<template>
  <div v-if="$_.isEmpty(contract)">
    <loading />
  </div>

  <div v-else>
    <apart>
      <p>Status</p>
      <status-label :status="`${contract.status}`" />
    </apart>

    <apart>
      <p>Site | Plan</p>
      <p>
        <router-link :to="{ path: `/sites/magic/${contract.site.ref.id}` }">
          <u>{{ contract.site.fqdn }}</u>
        </router-link>
        | {{ contract.product.name }}
      </p>
    </apart>

    <apart>
      <p>Term</p>
      <p>
        <strong>{{ contract.product.term.length }} month(s)</strong>
      </p>
    </apart>

    <apart>
      <p>Price</p>
      <p>
        <strong>{{
          $formatCurrency(contract.price.total, contract.price.currency)
        }}</strong>
        {{
          contract.price.isDiscountable
            ? "(before any discounts)"
            : "(final price)"
        }}
      </p>
    </apart>

    <apart>
      <p>Details</p>

      <p>
        <strong>
          <span v-if="contract.status == 2"
            >Payment due
            {{ $moment(contract.dateNextDue.toDate()).from() }}</span
          >
          <span v-else-if="contract.status == 1"
            >Renews {{ $moment(contract.dateNextDue.toDate()).from() }}</span
          >
          <span v-else-if="contract.status == 0"
            >Expires {{ $moment(contract.dateNextDue.toDate()).from() }}</span
          >
          <span v-else-if="contract.status == -1"
            >Lapsed {{ $moment(contract.dateNextDue.toDate()).from() }}</span
          >
        </strong>
      </p>
    </apart>

    <apart>
      <p>Next due</p>

      <p
        :class="
          contract.dateNextDue.toDate() < new Date() ? 'has-text-danger' : ''
        "
      >
        <strong>
          <span v-if="contract.status > 0">{{
            $moment(contract.dateNextDue.toDate()).format("Do MMM, YYYY")
          }}</span>

          <span v-else>–</span>
        </strong>
      </p>
    </apart>

    <apart>
      <p>Created</p>
      <p>
        <strong>{{ $moment(contract.dateCreated.toDate()).from() }}</strong>
      </p>
    </apart>

    <apart class="has-padding-top-100">
      <span />

      <contract-action
        :contract="contract"
        @updated="$emit('updated', contract)"
      />
    </apart>
  </div>
</template>

<script>
export default {
  name: "ContractDetails",
  components: {
    "contract-action": () => import("@shared/contracts/_contractAction")
  },
  props: {
    contract: {
      required: true,
      type: Object
    },
    adminContext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    contract: {
      immediate: true,
      handler(newContract, oldContract) {
        const _ = this.$_;
        if (_.isEmpty(newContract)) return;
        const picks = ["price", "dateNextDue"];
        const newPick = _.pick(newContract, picks);
        const oldPick = _.pick(oldContract, picks);
        if (!_.isEqual(newPick, oldPick))
          setTimeout(() => {
            this.$emit("updated", newContract);
          }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-method-img {
  width: 48px;
  vertical-align: middle;
  margin-right: 1.5em;
}
</style>
